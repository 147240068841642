import React from "react"
import { graphql, Link } from "gatsby"
import Slanted, { NormalHeading } from "../components/layout/slanted"
import Layout from "../components/layout"
import { Container } from "../components/layout"
import SEO from "../components/SEO"

const Punkter = ({ data }) => {
  const {
    allWpProgram: { edges },
    program,
  } = data
  const punktList = []
  edges.forEach(({ node: program }) => {
    program.kyrkoProgramFields.attSatser.forEach(({ sats }) => {
      punktList.push(<Link to={program.uri}>{sats}</Link>)
    })
  })
  const title = `${punktList.length} punkter för att ta tillbaka kyrkan!`
  const imageData = program.featuredImage.node.localFile.childImageSharp.fluid
  return (
    <Layout>
      <SEO
        title={title}
        image={imageData}
        description="Har du kort om tid? Läs vad vi vill göra i kyrkan i kortformat!"
      ></SEO>
      <Slanted imageData={imageData}>
        <NormalHeading align="right" white>
          {title}
        </NormalHeading>
      </Slanted>
      <Slanted innerClass="bg-white">
        <h1>Det här vill vi</h1>
        {punktList.map((sats, index) => (
          <div className="flex flex-row items-start md:items-center md:h-12 mb-4">
            <div className="text-3xl  w-10 font-gotham mr-4 text-center">
              {index + 1}.
            </div>
            <div className="w-full text-lg">{sats}</div>
          </div>
        ))}
        <p className="text-center">
          Tycker du att det låter vettigt och vill läsa mer?
        </p>
        <div className="flex flex-row justify-center">
          <Link to="/manifest" className="secondary-button">
            Läs vårt valmanifest!
          </Link>
        </div>
      </Slanted>
    </Layout>
  )
}

export default Punkter

export const query = graphql`
  query punkterQuery {
    program: wpPage(slug: { eq: "program" }) {
      title

      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    allWpProgram(sort: { fields: kyrkoProgramFields___order }) {
      edges {
        node {
          title
          uri
          kyrkoProgramFields {
            attSatser {
              sats
            }
            kortversion
          }
        }
      }
    }
  }
`
